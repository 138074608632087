import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Select from '@frontend/ui-kit/Components/Select';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import DynamicActionBar from '../../shared/DynamicActionBar';
import {Form, Field} from '../../shared/FormComponents';
import withBroadcastStepCommon from '../../../HOC/withBroadcastStepCommon';
import {requestAudience} from '../../../actions/broadcaster';
import {getBroadcastAudience} from '../../../selectors/broadcaster';
import {goNextWizardStep} from '../../../actions/shared';
import {validateRequired} from '../../../utils';
import {FORMS} from '../../../constants';
import {AUDIENCE_FILTER_OPTIONS, SPECIFIC_AUDIENCE_FILTER_OPTIONS, STATES_OPTIONS} from '../../../options';
import './index.scss';

/* istanbul ignore next */
// const validate = values => {
//     return {
//         timezone: validateRequired(values.timezone),
//         scheduled_for: validateRequired(values.scheduled_for)
//     };
// };

const AudienceStep = () => {
    const dispatch = useDispatch();
    const [isAudianceAvailable, setIsAudianceAvailable] = useState(false);

    const onChangeStates = async states => {
        dispatch(requestAudience({states}));
    };

    const onSubmit = async () => {
        dispatch(goNextWizardStep());
    };

    useEffect(() => {

    }, [dispatch]);

    const broadcastAudience = useSelector(getBroadcastAudience);
    const isAudiencePresent = broadcastAudience?.audienceCount > 0;

    return (
        <Form name={FORMS.broadcastAudienceStep} initialValues={{audience: '', states: ''}} onSubmit={onSubmit}>
            {({handleSubmit, values, form}) => {
                const onClickSubmitButton = isTest => () => {
                    form.change('is_test', isTest);
                };

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Row className='broadcast-wizard-step'>
                            <Column sm={6} className='broadcast-section broadcast-section_schedule'>
                                <Heading type={HEADING_TYPES['5']} className='broadcast-section__title'>Who would you like to send Broadcast to?</Heading>
                                <Field name='audience'>
                                    {props => <RadioGroup {...props} options={AUDIENCE_FILTER_OPTIONS} isLabelInline/>}
                                </Field>

                                <Row className='mt-10'>
                                    <Column sm={4} className='broadcast-section broadcast-section_schedule'>
                                        <Field name='filter'>
                                            {props => <Select {...props} options={SPECIFIC_AUDIENCE_FILTER_OPTIONS} label='Select a Filter'/>}
                                        </Field>
                                    </Column>
                                    <Column sm={4} className='broadcast-section broadcast-section_schedule'>
                                        <Field name='states' onChange={onChangeStates}>
                                            {props => <Select {...props} options={STATES_OPTIONS} label='Region' isRequired isMulti/>}
                                        </Field>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>

                        <DynamicActionBar>
                            {/* <Button type={BUTTON_TYPES.primary} isSubmit disabled={!isAudiencePresent} onClick={onClickSubmitButton(false)} data-testid='button-wizard-continue'> */}
                            <Button type={BUTTON_TYPES.primary} isSubmit disabled={isAudiencePresent} onClick={onClickSubmitButton(false)} data-testid='button-wizard-continue'>
                                Continue
                            </Button>
                        </DynamicActionBar>
                    </form>
                );
            }}
        </Form>
    );
};

AudienceStep.propTypes = {
    submit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    broadcast: PropTypes.shape({id: PropTypes.number})
};

export {AudienceStep as TestableAudienceStep};
export default withBroadcastStepCommon(AudienceStep);
