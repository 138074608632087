export const CHANGE_SIDEBAR_MODE = 'CHANGE_SIDEBAR_MODE';

export const SET_WORKSPACE = 'SET_WORKSPACE';
export const SET_STEP_ALIAS = 'SET_STEP_ALIAS';
export const RECEIVE_CSM_LIST = 'RECEIVE_CSM_LIST';
export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const UPDATE_ACTIVE_COMPANY = 'UPDATE_ACTIVE_COMPANY';
export const RECEIVE_TIMELINE = 'RECEIVE_TIMELINE';
export const UPDATE_TIMELINE_STEP = 'UPDATE_TIMELINE_STEP';
export const RECEIVE_BASIC_DATA = 'RECEIVE_BASIC_DATA';
export const RECEIVE_ADMIN_PORTAL_BASIC_DATA = 'RECEIVE_ADMIN_PORTAL_BASIC_DATA';
export const RECEIVE_COMPANIES_MAP = 'RECEIVE_COMPANIES_MAP';
export const SET_USER_ONBOARDED_DATA = 'SET_USER_ONBOARDED_DATA';
export const SET_REDIRECT_TO_URL = 'SET_REDIRECT_TO_URL';
export const RESET_REDIRECT_TO_URL = 'RESET_REDIRECT_TO_URL';

export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SET_WIZARD_ACTIVE_STEP = 'SET_WIZARD_ACTIVE_STEP';
export const GO_NEXT_WIZARD_STEP = 'GO_NEXT_WIZARD_STEP';
export const GO_PREV_WIZARD_STEP = 'GO_PREV_WIZARD_STEP';
export const RECEIVE_CORE_CARRIERS = 'RECEIVE_CORE_CARRIERS';
export const RECEIVE_ANCILLARY_CARRIERS = 'RECEIVE_ANCILLARY_CARRIERS';
export const RECEIVE_FORM_REVISIONS = 'RECEIVE_FORM_REVISIONS';
export const SET_FORMS_DIRTINESS = 'SET_FORMS_DIRTINESS';
export const SET_FORM_DIRTINESS = 'SET_FORM_DIRTINESS';
export const CLEAR_FORM_REVISIONS = 'CLEAR_FORM_REVISIONS';

export const SET_INTERNAL_MENU_ITEMS = 'SET_INTERNAL_MENU_ITEMS';
export const SET_INTERNAL_MENU_ACTIVE_ITEM = 'SET_INTERNAL_MENU_ACTIVE_ITEM';

export const AUTHORIZE = 'AUTHORIZE';
export const AUTHORIZE_BY_APP_SECRET = 'AUTHORIZE_BY_APP_SECRET';
export const UNAUTHORIZE = 'UNAUTHORIZE';
export const RECEIVE_TWO_STEP_AUTHORIZATION_DATA = 'RECEIVE_TWO_STEP_AUTHORIZATION_DATA';
export const AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED';

export const UPDATE_DELAY_STAMP = 'UPDATE_DELAY_STAMP';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';

export const SET_EXPANDED_TIMELINE_STEP = 'SET_EXPANDED_TIMELINE_STEP';
export const RECEIVE_IMPLEMENTATION_MANAGERS_LIST = 'RECEIVE_IMPLEMENTATION_MANAGERS_LIST';
export const RECEIVE_IMPLEMENTATION_SPECIALISTS_LIST = 'RECEIVE_IMPLEMENTATION_SPECIALISTS_LIST';
export const SWITCH_TIMELINE_IMPLEMENTATION_MANAGER = 'SWITCH_TIMELINE_IMPLEMENTATION_MANAGER';
export const RECEIVE_ADMIN_CONTACTS = 'RECEIVE_ADMIN_CONTACTS';
export const CLEAR_ADMIN_CONTACTS = 'CLEAR_ADMIN_CONTACTS';
export const ADD_ADMIN_CONTACT = 'ADD_ADMIN_CONTACT';

export const RECEIVE_CORE_PLANS = 'RECEIVE_CORE_PLANS';
export const CLEAR_CORE_PLANS = 'CLEAR_CORE_PLANS';
export const RECEIVE_ANCILLARY_PLANS = 'RECEIVE_ANCILLARY_PLANS';
export const CLEAR_ANCILLARY_PLANS = 'CLEAR_ANCILLARY_PLANS';
export const RECEIVE_PHARMACY_BENEFIT_MANAGERS = 'RECEIVE_PHARMACY_BENEFIT_MANAGERS';
export const RECEIVE_MEDICAL_TRAITS = 'RECEIVE_MEDICAL_TRAITS';
export const RECEIVE_WEBVIEW_PARAMS = 'RECEIVE_WEBVIEW_PARAMS';
export const RECEIVE_WALLET_CARDS = 'RECEIVE_WALLET_CARDS';
export const SET_IS_COPYING_ENABLED = 'SET_IS_COPYING_ENABLED';

export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';

export const RECEIVE_COMPANY_EMPLOYEES = 'RECEIVE_COMPANY_EMPLOYEES';
export const RECEIVE_COMPANY_EMPLOYEE_COUNT = 'RECEIVE_COMPANY_EMPLOYEE_COUNT';
export const RECEIVE_INDUSTRY_LIST = 'RECEIVE_INDUSTRY_LIST';
export const RECEIVE_REASON_LIST = 'RECEIVE_REASON_LIST';

export const RECEIVE_BENEFITS_CLASSES = 'RECEIVE_BENEFITS_CLASSES';

export const RECEIVE_ELIGIBILITY_PLANS = 'RECEIVE_ELIGIBILITY_PLANS';
export const RECEIVE_ELIGIBILITY_FILES_INFO_LIST = 'RECEIVE_ELIGIBILITY_FILES_INFO_LIST';
export const RECEIVE_ELIGIBILITY_BEN_ADMIN_LIST = 'RECEIVE_ELIGIBILITY_BEN_ADMIN_LIST';
export const SET_STARTED_FILE_EXPORT_IDS = 'SET_STARTED_FILE_EXPORT_IDS';
export const RECEIVE_EXPORTED_FILES = 'RECEIVE_EXPORTED_FILES';
export const DOWNLOAD_ELIGIBILITY_EXPORT_FILE = 'DOWNLOAD_ELIGIBILITY_EXPORT_FILE';
export const RECEIVE_BENEFITS_OVERVIEW = 'RECEIVE_BENEFITS_OVERVIEW';
export const RECEIVE_OPEN_ENROLLMENT_OVERVIEW = 'RECEIVE_OPEN_ENROLLMENT_OVERVIEW';

export const SET_ACTIVE_REPORTING_TAB = 'SET_ACTIVE_REPORTING_TAB';
export const SET_REPORTING_PLAN_PERIOD = 'SET_REPORTING_PLAN_PERIOD';
export const RECEIVE_REPORTING_INFO = 'RECEIVE_REPORTING_INFO';
export const DOWNLOAD_ACTIVATION_REPORT = 'DOWNLOAD_ACTIVATION_REPORT';
export const DOWNLOAD_PERFORMANCE_REPORT = 'DOWNLOAD_PERFORMANCE_REPORT';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const REQUEST_SEARCHED_MEMBERS = 'REQUEST_SEARCHED_MEMBERS';

export const RECEIVE_MARKETING_PAGES = 'RECEIVE_MARKETING_PAGES';
export const RECEIVE_MARKETING_PAGE_INFO = 'RECEIVE_MARKETING_PAGE_INFO';
export const DOWNLOAD_RESOURCE_FILE = 'DOWNLOAD_RESOURCE_FILE';

export const SET_ACTIVE_BROADCASTS_TAB = 'SET_ACTIVE_BROADCASTS_TAB';
export const RECEIVE_BROADCAST_TEMPLATES = 'RECEIVE_BROADCAST_TEMPLATES';
export const RECEIVE_BROADCASTS = 'RECEIVE_BROADCASTS';
export const SET_BROADCAST_TEMPLATE = 'SET_BROADCAST_TEMPLATE';
export const RECEIVE_BROADCAST = 'RECEIVE_BROADCAST';
export const CLEAR_BROADCAST = 'CLEAR_BROADCAST';
export const SET_BROADCASTER_ONBOARDED = 'SET_BROADCASTER_ONBOARDED';
export const RECEIVE_WALLET_LINKS = 'RECEIVE_WALLET_LINKS';
export const RECEIVE_AUDIENCE = 'RECEIVE_AUDIENCE';

export const SET_PDF_TEMPLATE_SUBJECT = 'SET_PDF_TEMPLATE_SUBJECT';
export const RECEIVE_PDF_REPORT_INFO = 'RECEIVE_PDF_REPORT_INFO';

export const RECEIVE_PLAN_MANAGER_PLANS = 'RECEIVE_PLAN_MANAGER_PLANS';
export const RECEIVE_PLAN_MANAGER_PLAN_NAMES = 'RECEIVE_PLAN_MANAGER_PLAN_NAMES';
export const RECEIVE_PLAN_MANAGER_PLAN_VERSIONS = 'RECEIVE_PLAN_MANAGER_PLAN_VERSIONS';
export const RECEIVE_PLAN_MANAGER_BENEFITS_CATEGORIES = 'RECEIVE_PLAN_MANAGER_BENEFITS_CATEGORIES';
export const RECEIVE_PLAN_MANAGER_BENEFITS_ORDERING = 'RECEIVE_PLAN_MANAGER_BENEFITS_ORDERING';
export const RECEIVE_CARRIER_MANAGER_CARRIERS = 'RECEIVE_CARRIER_MANAGER_CARRIERS';
export const SET_MULTIPLE_CHOICE_CHOICES = 'SET_MULTIPLE_CHOICE_CHOICES';
export const SET_MULTIPLE_CHOICE_VALUES = 'SET_MULTIPLE_CHOICE_VALUES';
export const CLEAR_MULTIPLE_CHOICE = 'CLEAR_MULTIPLE_CHOICE';
